/* Process YML files into HTML */
import React from 'react';
import StudyPage from '../components/StudyPage';

export default function Template(props) {
  const ctx = props.pageContext;
  const studyId = ctx.config.slug;

  if (studyId==='any') return <div>Any study</div>;
  return <StudyPage ctx={ctx} pagepath={props.path} />;
};
