/**
 * StudyLayout is a page wrapper providing a specified or default header
 * and a footer.
 * Supported props: config ctx currentpath theme fpOn fpOff
 */

import React from 'react';
import Nav from './NavToggleFlex';
import FixedPanel from './FixedPanel';
import Footer from './Footer';
import { CookieProvider } from './CookieContext';
import { setLinks } from '../util/pagetools';
import { localizer } from '../util/localizer';
import logo from '../images/upstate_logo.png';

export default function StudyLayout(props) {
  const cfg = props.config;
  const localized = localizer(props.ctx);
  const toplinks = (cfg.sections ? setLinks(cfg.sections) : null);
  const footdata = {
    ...cfg.footer,
    toplinks,
    sitename: cfg.sitename,
    siteurl: cfg.siteurl
  };
  const mainstyle = (props.theme ? {className: props.theme} : {});
  const cookieConfig = (cfg.homepage ? cfg.homepage.cookie : cfg.cookie);

  return (
    <div id="page">
      <CookieProvider content={cookieConfig}>
        <div id="layout-root">
          <header>
            <Nav toplinks={toplinks}
                 sitename={cfg.sitename}
                 theme="bg-white"
                 ctx={props.ctx}
                 currentpath={props.currentpath}
                 localizerfn={localized} />
            {props.fpOn && <FixedPanel src={cfg.fixedpanel} btnLabel={cfg.cta} visibleBelow={props.fpOn} visibleAbove={props.fpOff} />}
          </header>
          <main {...mainstyle}>{props.children}</main>
          <Footer src={footdata} localizerfn={localized} />
          <div className="hpad-10p">
            <img src={logo} alt="Upstate Global Health" className="w-200 py-16" />
          </div>
        </div>
      </CookieProvider>
    </div>
  );
};
