/**
 * A panel which appears at a fixed location on scrolling into a given range.
 * Supported props: src btnLabel visibleBelow visibleAbove linkto
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Safe from './Safe';
import { css } from '../util/pagetools';

export default function FixedPanel(props) {
  const linkto = (props.src && props.src.linkto ? props.src.linkto : '#screener');
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    const scrollFn = scrollMonitor(props.visibleBelow, props.visibleAbove);
    function enablePanel() { setHidden(scrollFn()); }
    function watchScroll() { window.addEventListener('scroll', enablePanel); }
    watchScroll();
    // Remove the listener
    return () => { window.removeEventListener('scroll', enablePanel); };
  });

  return (
    <div className={css(css('begin-fixed', (hidden?'hidx':'')), props.src.theme)}>
      <Safe className="begin-fixed-content" content={props.src.cta} />
      <Link to={linkto} className={css('fixed-panel-btn', props.src.btntheme)}>{props.btnLabel}</Link>
    </div>
  );
};

/**
 * Determine when scrolling enters or exits a given range, specified by one
 * or two elements whose positions represent lower and upper bounds.
 * @param string - id of element of the lower bound
 * @param string - id of element of the upper bound
 * @return boolean
 */
function scrollMonitor(lowerElement, upperElement) {
  function getTop(el) { return el.getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop); }
  const lowerEl = lowerElement ? document.getElementById(lowerElement) : null;
  const upperEl = upperElement ? document.getElementById(upperElement) : null;
  return function() {
    const lowerBoundary = (lowerEl ? lowerEl.scrollHeight+getTop(lowerEl) : 0);
    const upperBoundary = (upperEl ? getTop(upperEl)-200 : 0);
    const hideIt = (upperBoundary>0 ? (window.scrollY<lowerBoundary || window.scrollY>upperBoundary) : (window.scrollY<lowerBoundary));
    return hideIt;
  };
}
