/**
 * Banner provides a container with a title, a button, and an image.
 * Supported props: title subtitle startstudy banner
 */
import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { css } from '../util/pagetools';
import { renderParagraphs } from '../util/rendertools';
import Safe from './Safe';

export default function PageBanner(props) {
  const cfg = props.config;

  return (
    <div id="banner" className={css('banner', cfg.theme)}>
      <div className="banner-left">
        <Safe className="banner-title" type="h1" content={cfg.title} />
        {renderParagraphs(cfg.subtitle, 'banner-text')}
        <a href="#screener" className={css('banner-btn', cfg.btntheme)}>{cfg.cta}</a>
      </div>
      <div>
        <StaticImage src='../images/banner.jpg' alt="main banner" layout="fullWidth" className="banner-img" />
        <StaticImage src='../images/banner_mobile.jpg' alt="main banner" layout="fullWidth" className="banner-img-mobile" />
      </div>
    </div>
  );
};
